/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @font-face {
    font-family: Bicubik;
    src: url('./fonts/bicubik-webfont.woff2') format('woff2'), url('./fonts/bicubik-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}
